import {analyticsAllowed} from './consent.js';
import config from './config.js';

const storage = {
    get,
    set
};
export default storage;

/**
 * Get value from localStorage if consent allows, remove it otherwise
 * @param {Window} window 
 * @param {string} key 
 */
function get(window, key) {
    return storageOp(window, key, (localStorage, k) => localStorage.getItem(k));
}

/**
 * Set value to localStorage if consent allows, remove it otherwise
 * @param {Window} window 
 * @param {string} key 
 * @param {*} value 
 */
function set(window, key, value) {
    storageOp(window, key, (localStorage, k) => localStorage.setItem(k, value));
}

function storageOp(window, key, cb) {
    key = `${config.storagePrefix}-${key}`;
    try {
        const {localStorage} = window;
        if (analyticsAllowed(window)) {
            return cb(localStorage, key);
        }
        localStorage.removeItem(key);
    } catch (e) {
        // empty
    }
}
