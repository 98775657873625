/**
 * Get a value of a cookie by name
 * @param {Window} window 
 * @param {string} name
 * @returns {string | null}
 */
export default function readCookie(window, name) {
    const re = new RegExp(`\\b${name}\\s*=\\s*([0-9a-f\\-\\|]+)`);
    const match = re.exec(window.document.cookie);
    return match && match[1];
}
